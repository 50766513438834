import React, { Component } from "react"
import Particles from "react-tsparticles";
import { BrowserView } from "react-device-detect";

/* Base Component */
import ComponentLayout from 'layouts/component.layout'

/* Style */
import * as Stage from './stage.module.scss'

class StageComponent extends Component {
  constructor(props) {
    super()
    this.state = {
      height: ''
    }

    this.title = props.props.title
    this.subtitle = props.props.subtitle
  }

  componentDidMount() {
    const height = document.querySelector('.canvas-container').clientHeight
    this.setState({ height })
  }

  render() {
    return (
      <ComponentLayout className={`${Stage.py} grid-flow-col relative canvas-container`}>
        <BrowserView className="absolute top-0 bottom-0 right-0 left-0">
            <Particles
              id="tsparticles"
              height={this.state.height}
              className="z-0 col-start-1 col-end-3 md:col-start-1 md:col-end-7 lg:col-start-1 lg:col-end-13"
              options={{
                fpsLimit: 60,
                detectRetina: true,
                particles: {
                  collisions: {
                    enable: true
                  },
                  color: {
                    value: "#44d7b6"
                  },
                  links: {
                    enable: false,
                  },
                  move: {
                    angle: 90,
                    direction: "none",
                    enable: true,
                    outMode: "bounce",
                    random: false,
                    speed: 1,
                    straight: false
                  },
                  number: {
                    density: {
                      enable: true,
                      area: 700,
                      factor: 1000
                    },
                    limit: 0,
                    value: 20
                  },
                  opacity: {
                    value: 1
                  },
                  shape: {
                    type: "circle"
                  },
                  size: {
                    random: true,
                    value: 10
                  }
                }
              }}
            />
        </BrowserView>
        <header className="col-start-1 col-end-3 md:col-start-1 md:col-end-7 lg:col-start-2 lg:col-end-12 relative z-10">
          <h1 className={`${Stage.animate__fadeInUp} text-white text-center leading-medium text-4xl md:text-5xl md:leading-xl xl:text-6xl`}>
            { this.title }
            <span className="mt-4 leading-standard text-2xl md:text-4xl md:leading-small md:mt-6 xl:text-5xl xl:leading-large xl:mt-8">
              { this.subtitle }
            </span>
          </h1>
        </header>
      </ComponentLayout>
    )
  }
}

export default StageComponent
